import React from "react";
import "..//css/haku.scss";
import "../css/CardCSS/about.css";
import "../css/about.scss";
import LoremIpsum from "react-lorem-ipsum";
import GridView from "../components/pages/GamesPage/GridView";

import profileDataInfo from "../json/profileData.json";
import Card5 from "../components/elements/Card5";

import Card5Data from "../json/card5Data.json";
import MouseTrail from "../components/haku/MouseTrail";

export default function About() {
  return (
    <div>
      <MouseTrail backGroundColor="#14113a" inner={0} />
      <br />

      <div className="backgroundImageTop"></div>
      <div className="about_Container haku-from-bottom ">
        <div className="about_Title">
          About <div className="o1"> us</div>
        </div>
        <div className="about_picContainer">
          {/* <div>
            <img
              className="about_mainPicture"
              src="https://risinghighacademy.com/wp-content/uploads/2021/05/Top-12-Hyper-Casual-Games-April-2020.jpg"
            />
          </div> */}
          <div className="about_picText1">
            <div className=" haku-from-hide-ani" haku-ani-delay={1000}>
              <div className="haku-from-hide-ani-text ">
              Sigma started as a gaming company, expanded into web app development,
               and is now driven by AI technology. Since 2021, we’ve developed 350+
                games, 50+ playable ads, and five major platforms. Today, we focus on
                 AI-powered solutions, including advanced chatbots and AI-generated avatars
                 , shaping the future of digital interaction.
              </div>
              <div className="haku-from-hide-ani-mask"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="backgroundImageMiddle"></div>

      <div className="about_textContainer">
        <div className="" haku-ani-delay={0}>
          <Card5 data={Card5Data[0]} />
        </div>
        <Card5 data={Card5Data[1]} />
        {/* <Card5 data={Card5Data[2]} /> */}
      </div>

      <div className="backgroundImageBottom"></div>
      <div className="members">
        <div className="about-mainContainer about_Container ">
          <p>
            <div className="simple-wave-3"></div>
          </p>
          <GridView
            data={profileDataInfo}
            title="Members"
            cardName="profile"
            margin="none"
          ></GridView>
        </div>
      </div>
    </div>
  );
}
