import LoremIpsum from "react-lorem-ipsum";
import '../../css/project.css';
import nextjsIcon from '../../assets/images/icons/nextJs.png';
import nodejsIcon from '../../assets/images/icons/NodeJs.png';
import ganAi from '../../assets/images/icons/ganAi.png';
import tailwindIcon from '../../assets/images/icons/tailWind.png';
import framerMotionIcon from '../../assets/images/icons/framerMotion.webp';
import avatarImage from '../../assets/images/avatar/avatar.png'
export default function ProjectAvatar() {
  return (
    <div className="Project">
        <div className="Project-Title">
            AI Generated Avatar in E-Commerce
        </div>
        <div className="Project-Title-About">
            <div>Web-App</div>
            <div>Timeline of project : 2 months</div>
        </div>
        <hr/>
        <div className="Project-Title-2">
            Project Overview
        </div>
        <div className="Project-Paragraph">
            This is the MVP version of the full product which will be a visual conversational chatbot taking questions from the user as voice inputs and an avatar speaking back. This version uses buttons with predetermined topics as user input and pre-rendered videos as system responses.
        </div>
        <div className="Project-Avatar">
            <img src={avatarImage}/>
        </div>
        <div className="Project-Title-2">
            Project role & responsibilities
        </div>
        <div className="Project-Paragraph">
            The project involves the development of a user interface for a visually engaging chatbot. This includes the implementation of pre-prompt selection functionalities and fine-tuning of the chatbot's conversational capabilities.
        </div>
        <div className="Project-Title-2">
            Studies technology
        </div>
        <div className="Project-Studies" >
            <div style={{width:"250px"}} className="Project-Paragraph">
                CodeTalker, GENEFACE, OPT, LipNeRF, StyleTalk, DiffTalk, Diffused heads powered by Gan.AI
            </div>
            <div className="Project-ImageContainer">
                <img src= {ganAi}/>
                <p>Gan.AI</p>
            </div>
        </div>
        <div className="Project-Title-2">
            Types of technologies used
        </div>
        <div className="Project-Tech">
        <div>
                <img src= {framerMotionIcon}/>
                <p>Framer Motion</p>
            </div>
            <div>
                <img src= {nodejsIcon}/>
                <p>NodeJs</p>
            </div>
            <div>
                <img src= {tailwindIcon}/>
                <p>Tailwind</p>
            </div>
            <div>
                <img src= {nextjsIcon}/>
                <p>Next js</p>
            </div>
        </div>
    </div>
)};