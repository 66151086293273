import LoremIpsum from "react-lorem-ipsum";
import '../../css/project.css';
import nodejsIcon from '../../assets/images/icons/NodeJs.png';
import streamLitIcon from '../../assets/images/icons/streamlit.png';
import openAIIcon from '../../assets/images/icons/openAI.png';
import pythonIcon from '../../assets/images/icons/python.webp';
import awsIcon from '../../assets/images/icons/aws.png';
import lessIcon from '../../assets/images/icons/Less.png';
import googlecloudIcon from '../../assets/images/icons/GoogleCloud.png';
import postmanIcon from '../../assets/images/icons/postman.webp';
import prismaIcon from '../../assets/images/icons/Prisma.png';
import postgrasqlIcon from '../../assets/images/icons/PostgreSQL.png';
import githubIcon from '../../assets/images/icons/github.png';
import gitlabIcon from '../../assets/images/icons/gitlabIcon.png';
import tailwindIcon from '../../assets/images/icons/tailWind.png';
import framerMotionIcon from '../../assets/images/icons/framerMotion.webp';
import picture1 from '../../assets/images/ManagementSystem/1.png';
import picture2 from '../../assets/images/ManagementSystem/2.png';
export default function ProjectManagement() {
  return (
    <div className="Project">
        <div className="Project-Title">
            Content and Stakeholder Management System
        </div>
        <div className="Project-Title-About">
            <div>Web-App</div>
            <div>Timeline of project : 11 months</div>
        </div>
        <hr/>
        <div className="Project-Title-2">
            Project Overview
        </div>
        <div className="Project-Paragraph">
            Advertising management platform designed to facilitate the placement of orders for audio advertisement creation by business seeking to promote their products or services with the functionality of AI to create a fitting audio ad scripts based on entered contents.
        </div>
        <div className="Project-Management">
            <img src={picture1}/>
            <img src={picture2}/>
        </div>
        <div className="Project-Title-2">
            Project role & responsibilities
        </div>
        <div className="Project-Paragraph">
            The project includes the development of an account type system designed to assign permission levels bason on user context. Additionally, a submission window featuring a comprehensive form outlining clinet-provided advertisement content instruction, with an added function of multiple users to edit the submission form. Furthermore, a milestone tracking system for communication between stakeholders and monitoring the progress of a campaign.
        </div>
        <div className="Project-Title-2">
            Studies technology
        </div>
        <div className="Project-Studies" >
            <div style={{width:"250px"}} className="Project-Paragraph">
                The study on chatbots was conducted with the intention of implementing future AI related functions to be added into the platform
            </div>
            <div className="Project-ImageContainer">
                <img src= {pythonIcon}/>
                <p>Python</p>
            </div>
            <div className="Project-ImageContainer">
                <img src= {openAIIcon}/>
                <p>Open AI</p>
            </div>
            <div className="Project-ImageContainer">
                <img src= {streamLitIcon}/>
                <p>Streamlit</p>
            </div>
        </div>
        <div className="Project-Title-2">
            Types of technologies used
        </div>
        <div className="Project-Tech">
            <div>
                <img src= {lessIcon}/>
                <p>LESS</p>
            </div>
            <div>
                <img src= {awsIcon}/>
                <p>AWS</p>
            </div>
            <div>
                <img src= {framerMotionIcon}/>
                <p>Framer Motion</p>
            </div>
            <div>
                <img src= {githubIcon}/>
                <p>GitHub</p>
            </div>
            <div>
                <img src= {nodejsIcon}/>
                <p>NodeJs</p>
            </div>
            <div>
                <img src= {gitlabIcon}/>
                <p>Gitlab</p>
            </div>
            <div>
                <img src= {postgrasqlIcon}/>
                <p>PostgreSQL</p>
            </div>
            <div>
                <img src= {prismaIcon}/>
                <p>Prisma</p>
            </div>
            <div>
                <img src= {postmanIcon}/>
                <p>Postman</p>
            </div>
            <div>
                <img src= {googlecloudIcon}/>
                <p>Google Cloud</p>
            </div>
            <div>
                <img src= {tailwindIcon}/>
                <p>Tailwind</p>
            </div>
        </div>
    </div>
)};