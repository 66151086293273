import React, { useRef, useEffect } from "react";
import { useLocation, Switch, Router, Route } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";
import "./css/bootstrap.min.css";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Triangles from "./views/Triangles";
import Home from "./views/Home";
import ThreeJs from "./views/ThreeJs";
import HakuTest from "./views/HakuTest";
import Tuvshuu from "./views/TuvshuuPlayground";
import About from "./views/About";
import Contact from "./views/Contact";
import Games from "./views/Games";
import Project from './views/Projects/ProjectGym';
import EnvironmentTest from "./views/Environment";
import HakuT from "./views/Haku";
import { delay } from "lodash";
import Haku3d from "./views/HakuTest3d";
import Header from "./components/haku/Navigation";
import Footer from "./components/haku/Footer";
import ProjectAvatar from "./views/Projects/ProjectAvatar";
import ProjectManagement from "./views/Projects/ProjectManagement";
import ProjectSport from "./views/Projects/ProjectSport";
import BodyMaker from "./views/Projects/GameBodyMaker";
import RoadDemolish from "./views/Projects/GameRoadDemolisher";
import CityMonster from "./views/Projects/GameCityMonster";
import ContentCreation from "./views/Projects/ContentCreation";
import ProjectChatbot from "./views/Projects/ProjectChatbot";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

export function OnScroll() {
  var elems = document.querySelectorAll(
    ".haku-from-bottom, .haku-from-top, .haku-from-left, .haku-from-right, .haku-from-scale"
  );
  var revealPoint = 0;
  var windowHeight = window.innerHeight;
  var delta = windowHeight + revealPoint;
  elems.forEach((i) => {
    let delay = i.getAttribute("haku-ani-delay");
    if (delay == null) {
      delay = 0;
    }
    if (i.getBoundingClientRect().top < delta) {
      if (!i.classList.contains("haku-from-active")) {
        setTimeout(() => {
          i.classList.add("haku-from-active");
        }, delay);
      }
    } else {
      i.classList.remove("haku-from-active");
    }
    if (i.getBoundingClientRect().bottom < 0) {
      i.classList.remove("haku-from-active");
    }
  });

  elems = document.querySelectorAll(".haku-from-hide-ani");
  elems.forEach((i) => {
    let delay = i.getAttribute("haku-ani-delay");
    let rev = i.getAttribute("reverse");
    if (rev == null) {
      rev = false;
    }
    if (delay == null) {
      delay = 0;
    }
    let className = "haku-from-hide-ani-active" + (rev == 0 ? "2" : "");
    if (i.getBoundingClientRect().top < delta) {
      if (!i.classList.contains(className)) {
        setTimeout(() => {
          i.classList.add(className);
        }, delay);
      }
    }
  });
}

function SetReveal() {
  window.removeEventListener("scroll", OnScroll);
  window.addEventListener("scroll", OnScroll);
}
// console.log(url);
const App = () => {
  useEffect(() => {
    OnScroll();
    SetReveal();
  }, []);
  var urlpar = new URLSearchParams(window.location.search);
  var page;
  switch (urlpar.get("page")) {
    case "Home":
      page = <Home />;
      break;
    case "About":
      page = <About />;
      break;
    case "Projects":
      page = <Games />;
      break;
    case "Contact":
      page = <Contact />;
      break;
    case "Project":
      page = <Project />;
      break;
    case "ProjectAvatar":
      page = <ProjectAvatar />;
      break;
    case "ProjectManagement":
      page = <ProjectManagement />;
      break;
    case "ProjectSport":
      page = <ProjectSport />;
      break;
    case "ProjectChatbot":
      page = <ProjectChatbot />;
      break;
    case "GameBodyMaker":
      page = <BodyMaker />;
      break;
    case "GameRoadDemolish":
      page = <RoadDemolish />;
      break;
    case "GameCityMonster":
      page = <CityMonster />;
      break;
    case "ContentCreation":
      page = <ContentCreation/>
      break;
    default:
      page = <Home />;
      break;
  }
  return (
    <div>
      <Header />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <main className="site-content">{page}</main>
      <Footer />
    </div>
  );
};

export default App;
