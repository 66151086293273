import { LoremIpsum } from "react-lorem-ipsum";
import Styly from "../../../assets/images/Partners/Styly.png";
import Spotify from "../../../assets/images/Partners/Spotify.png";
import Kddi from "../../../assets/images/Partners/Kddi.png";
import Redd from "../../../assets/images/Partners/Redd.png";
import "../../../css/about-partners.css";
export default function HomePartners() {
  return (
    <div className="Home-Partners" style={{position: "relative", width: "70%", margin: "auto", marginTop: "7rem" }}>
        <h1>Business Partners</h1>
        <div>
            <img src={Styly} />
            <img src={Spotify} />
            <img src={Kddi} />
            <img src={Redd} />
        </div>
    </div>
  );
}
