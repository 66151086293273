import LoremIpsum from "react-lorem-ipsum";
import '../../css/project.css';
import reactIcon from '../../assets/images/icons/react.png';
import githubIcon from '../../assets/images/icons/github.png';
import nodejsIcon from '../../assets/images/icons/NodeJs.png';
import gitlabIcon from '../../assets/images/icons/gitlabIcon.png';
import postgrasqlIcon from '../../assets/images/icons/PostgreSQL.png';
import prismaIcon from '../../assets/images/icons/Prisma.png';
import postmanIcon from '../../assets/images/icons/postman.webp';
import googlecloudIcon from '../../assets/images/icons/GoogleCloud.png';
import nextjsIcon from '../../assets/images/icons/nextJs.png';
import tailwindIcon from '../../assets/images/icons/tailWind.png';
import framerMotionIcon from '../../assets/images/icons/framerMotion.webp';
import gymImage1 from '../../assets/images/gym/Gym1.png';
import gymImage2 from '../../assets/images/gym/Gym2.png';
import gymImage3 from '../../assets/images/gym/Gym3.png';
export default function Projects() {
  return (
    <div className="Project">
        <div className="Project-Title">
            Gym Management Platform
        </div>
        <div className="Project-Title-About">
            <div>Web-app</div>
            <div>Timeline of project : 3 months</div>
        </div>
        <hr/>
        <div className="Project-Title-2">
            Project Overview
        </div>
        <div className="Project-Paragraph">
            All in one Gym management platform for both un-staffed and staffed gym with multiple user roles & interfaces such as admin, gym staff, end users. This platform offers end users the ability to sign up, reserve, order items for the gym & check in for un-staffed gyms. For Admins they can monitor the status of each gyms and their reservations as well as visualize the revenue of each gyms from their internal dashboard.
        </div>
        <div className="Project-GymImages">
            <img src={gymImage1}/>
            <img src={gymImage2}/>
            <img src={gymImage3}/>
        </div>
        <div className="Project-Title-2">
            Project role & responsibilities
        </div>
        <div className="Project-Paragraph">
            We developed all of the platforms features in house starting from design, back end & front end. Furthermore we migrated the clients end user data from their previous CRM platform to the new one.
        </div>
        <div className="Project-Title-2">
            Types of technologies used
        </div>
        <div className="Project-Tech">
            <div>
                <img src= {reactIcon}/>
                <p>React</p>
            </div>
            <div>
                <img src= {githubIcon}/>
                <p>Github</p>
            </div>
            <div>
                <img src= {nodejsIcon}/>
                <p>NodeJs</p>
            </div>
            <div>
                <img src= {gitlabIcon}/>
                <p>Gitlab</p>
            </div>
            <div>
                <img src= {postgrasqlIcon}/>
                <p>PostgresSql</p>
            </div>
            <div>
                <img src= {prismaIcon}/>
                <p>Prisma</p>
            </div>
            <div>
                <img src= {postmanIcon}/>
                <p>Postman</p>
            </div>
            <div>
                <img src= {googlecloudIcon}/>
                <p>Google Cloud</p>
            </div>
            <div>
                <img src= {nextjsIcon}/>
                <p>Next js</p>
            </div>
            <div>
                <img src= {tailwindIcon}/>
                <p>Tailwind</p>
            </div>
            <div>
                <img src= {framerMotionIcon}/>
                <p>Framer Motion</p>
            </div>
        </div>
    </div>
)};