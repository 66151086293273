import LoremIpsum from "react-lorem-ipsum";
import '../../css/project.css';
import unityIcon from '../../assets/images/icons/Unity.png';
import mayaIcon from '../../assets/images/icons/Maya.webp';
import blenderIcon from '../../assets/images/icons/blender.webp';
import picture1 from '../../assets/images/gameScreenShots/BodyMaker.png';
import data from '../../json/ProjectsGamesMore.json';


export default function BodyMaker() {
  return (
    <div className="Project">
        <div className="Project-Title">
            {data[0].title}
        </div>
        <div className="Project-Title-About">
            <div>Mobile-Game</div>
            <div>Timeline of project : 2 months</div>
        </div>
        <hr/>
        <div className="Project-Title-2">
            Project Overview
        </div>
        <div className="Project-Paragraph">
            {data[0].text1}
        </div>
        <div className="Project-Game">
            <img src={picture1}/>
        </div>
        <div className="Project-Title-2">
            Project role & responsibilities
        </div>
        <div className="Project-Paragraph">
            {data[0].text2}
        </div>
        <div className="Project-Title-2">
            Studies technology
        </div>
        <div className="Project-Title-2">
            Types of technologies used
        </div>
        <div className="Project-Tech">
            <div>
                <img src={unityIcon}/>
                <p>Unity</p>
            </div>
            <div>
                <img src={mayaIcon}/>
                <p>Maya</p>
            </div>
            <div>
                <img src={blenderIcon}/>
                <p>Blender</p>
            </div>
        </div>
    </div>
)};