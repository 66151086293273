import LoremIpsum from "react-lorem-ipsum";
import '../../css/project.css';
import videoUrl from '../../assets/video/promoVideoSaloon.mp4';

export default function ContentCreation() {
  return (
    <div className="Project">
        <div className="Project-Title">
            Saloon Promotion Video
        </div>
        <div className="Project-Title-About">
            <div>Saloon Promotion Video</div>
            <div>Timeline of project : 1 months</div>
        </div>
        <hr/>
        <div className="Project-Title-2">
            Project Overview
        </div>
        <div className="Project-Paragraph">
        </div>
        <div className="Project-Sport">
            <video src={videoUrl} controls={true} autoPlay={true} ></video>
            {/* <img src={picture1}/> */}
        </div>
        <div className="Project-Tech">
            {/* <div>
                <img src={unityIcon}/>
                <p>Unity</p>
            </div>
            <div>
                <img src={mayaIcon}/>
                <p>Maya</p>
            </div>
            <div>
                <img src={blenderIcon}/>
                <p>Blender</p>
            </div> */}
        </div>
    </div>
)};