import LoremIpsum from "react-lorem-ipsum";
import '../../css/project.css';
import pythonIcon from '../../assets/images/icons/python.webp';
import googleIcon from '../../assets/images/icons/GoogleCloud.png';
import postGreIcon from '../../assets/images/icons/PostgreSQL.png';
import expressjsIcon from '../../assets/images/icons/express-js.png';
import prismaIcon from '../../assets/images/icons/Prisma.png';
import githubIcon from '../../assets/images/icons/github.png';
import openAI from '../../assets/images/icons/openAI.png';
import neo4jIcon from '../../assets/images/icons/neo4j.png';
import nextjsIcon from '../../assets/images/icons/nextJs.png';
import tailwindIcon from '../../assets/images/icons/tailWind.png';
import chatbotImage from '../../assets/images/customChatbot/morpho.PNG'
export default function ProjectChatbot() {
  return (
    <div className="Project">
        <div className="Project-Title">
            Custom AI Chatbot
        </div>
        <div className="Project-Title-About">
            <div>Web-App</div>
            <div>Timeline of project : 6 months</div>
        </div>
        <hr/>
        <div className="Project-Title-2">
            Project Overview
        </div>
        <div className="Project-Paragraph">
            Custom AI assistant that can help you with information retrieval, answering questions, and providing assistance with various tasks. It can search through documents, provide summaries, and help you find the information you need.
        </div>
        <div className="Project-Avatar">
            <img src={chatbotImage}/>
        </div>
        <div className="Project-Title-2">
            Project role & responsibilities
        </div>
        <div className="Project-Paragraph">
            The project focuses on developing a user interface for a customized AI chatbot assistant, ensuring a visually engaging experience. This includes implementing tailored pre-prompt selection features and fine-tuning the chatbot’s conversational abilities to meet specific user needs.
        </div>
        {/* <div className="Project-Title-2">
            Studies technology
        </div> */}
        {/* <div className="Project-Studies" >
            <div style={{width:"250px"}} className="Project-Paragraph">
                CodeTalker, GENEFACE, OPT, LipNeRF, StyleTalk, DiffTalk, Diffused heads powered by Gan.AI
            </div>
            <div className="Project-ImageContainer">
                <img src= {ganAi}/>
                <p>Gan.AI</p>
            </div>
        </div> */}
        <div className="Project-Title-2">
            Types of technologies used
        </div>
        <div className="Project-Tech">
            <div>
                <img src= {pythonIcon}/>
                <p>Python</p>
            </div>
            <div>
                <img src= {googleIcon}/>
                <p>Google Cloud</p>
            </div>
            <div>
                <img src= {postGreIcon}/>
                <p>PostgreSQL</p>
            </div>
            <div>
                <img src= {expressjsIcon}/>
                <p>Express js</p>
            </div>
            <div>
                <img src= {prismaIcon}/>
                <p>Prisma</p>
            </div>
            <div>
                <img src= {githubIcon}/>
                <p>GitHub</p>
            </div>
            <div>
                <img src= {openAI}/>
                <p>OpenAI</p>
            </div>
            <div>
                <img src= {neo4jIcon}/>
                <p>Neo4j</p>
            </div>
            <div>
                <img src= {tailwindIcon}/>
                <p>Tailwind</p>
            </div>
            <div>
                <img src= {nextjsIcon}/>
                <p>Next js</p>
            </div>
        </div>
    </div>
)};