import { LoremIpsum } from "react-lorem-ipsum";
import "../../../css/about-projects.css";
import blobIcon from "../../../assets/images/blob-haikei.png";
import arrowImage from '../../../assets/images/icons/arrow.png'
import Data from '../../../json/homeProjectsData.json'
export default function HomeProjects() {
  return (
    <div className="Home-Projects" style={{position: "relative", width: "80%", margin: "auto",marginTop:"1rem"}}>
        <div className="Home-Projects-Container">
            {Data.map((data) => {
                return (
                    <div className="Home-Projects-Project haku-from-left">
                        <img src={data.picUrl} />
                        <div>
                            <div className="Home-Projects-ProjectHeader">{data.title}</div>
                            <div className="Home-Projects-ProjectParagraph">
                                {data.description}
                                <a href={data.linkUrl}>Read More</a>
                            </div>
                        </div>
                    </div>
                );
            })}
            {/* <div className="Home-Projects-Project haku-from-left">
                <img src={blobIcon} />
                <div>
                    <div className="Home-Projects-ProjectHeader">Project</div>
                    <div className="Home-Projects-ProjectParagraph">
                        <LoremIpsum p={1} />
                    </div>
                </div>
            </div> */}
        </div>
        <div className="Home-Projects-More">
            <a href="/index.html?page=Projects">
                More Projects
                <img src={arrowImage} />
            </a>
        </div>
      {/* <LoremIpsum p={1}></LoremIpsum> */}
    </div>
  );
}
