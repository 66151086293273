import LoremIpsum from "react-lorem-ipsum";
import '../../css/project.css';
import postmanIcon from '../../assets/images/icons/postman.webp';
import githubIcon from '../../assets/images/icons/github.png';
import sassIcon from '../../assets/images/icons/sass.png';
import nextIcon from '../../assets/images/icons/nextJs.png';
import gitlabIcon from '../../assets/images/icons/gitlabIcon.png';
import picture1 from '../../assets/images/sportsgifting/1.png';
import picture2 from '../../assets/images/sportsgifting/2.png';
import picture3 from '../../assets/images/sportsgifting/3.png';


export default function ProjectSport() {
  return (
    <div className="Project">
        <div className="Project-Title">
            AR Game System Backend for Soccer Matches
        </div>
        <div className="Project-Title-About">
            <div>Web-App</div>
            <div>Timeline of project : 2 months</div>
        </div>
        <hr/>
        <div className="Project-Title-2">
            Project Overview
        </div>
        <div className="Project-Paragraph">
            A Web-based platform where users can purchase gift points. These gift points are then utilized within the integrated AR game, which simulates a soccer showdown where players kick a ball to score goals. The game experience is enhanced by the web system dividing players into two teams, encouraging competition as team strive to accumulate the highest number of scored goal points. On the web platform, users can navigate through a dashboard displaying their current point scores.
        </div>
        <div className="Project-Sport">
            <img src={picture1}/>
            <img src={picture2}/>
            <img src={picture3}/>
        </div>
        <div className="Project-Title-2">
            Project role & responsibilities
        </div>
        <div className="Project-Paragraph">
            We handled the development of a web platform with functionalities including a login system and account management, a point purchase system for users to buy virtual currency supported by the external platform, and a real time score dashboard with a ranking system.
        </div>
        <div className="Project-Title-2">
            Types of technologies used
        </div>
        <div className="Project-Tech">
            <div>
                <img src={nextIcon}/>
                <p>NextJs</p>
            </div>
            <div>
                <img src={sassIcon}/>
                <p>SASS</p>
            </div>
            <div>
                <img src={githubIcon}/>
                <p>GitHub</p>
            </div>
            <div>
                <img src= {gitlabIcon}/>
                <p>Gitlab</p>
            </div>
            <div>
                <img src= {postmanIcon}/>
                <p>Postman</p>
            </div>
        </div>
    </div>
)};