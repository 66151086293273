import { Form } from "react-bootstrap";
import React, { useState } from "react";
import "../../../css/haku.scss";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, child } from "firebase/database";
import { reverse } from "lodash";
import emailjs from "emailjs-com";

// OLD IDK WHY BUT IT'S HERE 

// const firebaseConfig = {
//   apiKey: "AIzaSyC6NmUreLPjKcEAyylrHIWr-W_KrnPwONk",
//   authDomain: "sigmawebsite-3bac4.firebaseapp.com",
//   databaseURL: "https://sigmawebsite-3bac4-default-rtdb.firebaseio.com",
//   projectId: "sigmawebsite-3bac4",
//   storageBucket: "sigmawebsite-3bac4.appspot.com",
//   messagingSenderId: "395036278611",
//   appId: "1:395036278611:web:554ac04f8e623f8a322ba6",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const database = getDatabase(app);

// function SendData(e) {
//   e.preventDefault();
//   var data = {
//     firstName: document.getElementById("firstName").value,
//     lastName: document.getElementById("lastName").value,
//     email: document.getElementById("email").value,
//     sms: document.getElementById("sms").value,
//     fiber: document.getElementById("fiber").value,
//     skype: document.getElementById("skype").value,
//     title: document.getElementById("title").value,
//     company: document.getElementById("company").value,
//   };
//   get(child(ref(database), "num")).then((snapshot) => {
//     if (snapshot.exists()) {
//       set(ref(database, "sms/" + (snapshot.val() + 1)), data);
//       document.cookie = "";
//       set(ref(database, "num"), snapshot.val() + 1);
//       document.getElementById("firstName").value = "";
//       document.getElementById("lastName").value = "";
//       document.getElementById("email").value = "";
//       document.getElementById("sms").value = "";
//       document.getElementById("fiber").value = "";
//       document.getElementById("skype").value = "";
//       document.getElementById("title").value = "";
//       document.getElementById("company").value = "";
//       alert("complete")
//     } else {
//     }
//   });
// }


function SendEmail(e) {
  e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it
  // console.log(e.target);
  emailjs
    .sendForm(
      "service_zit4uzq",
      "template_9jt1ydb",
      e.target,
      "d2fOY9m0FNvfm3rlO"
    )
    .then(
      (result) => {
        window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
      },
      (error) => {
        console.log(error.text);
      }
    );
}

export default function HomeContact() {


  return (
    <div
      className="homeContactContainer"
      style={{ maxHeight: "fitContent", margin: "auto" }}
    >
      <div
        className="home-contact-title"
        style={{ margin: "auto", marginTop: "2rem" }}
      >
        Contact Us
      </div>
      <form
        style={{ margin: "auto", marginTop: "2rem" }}
        onSubmit={SendEmail}
      >
        <table className="home-contact-table" style={{ margin: "auto" }}>
          <tr
            className="home-contact-table-row"
            style={{ border: "top-right" }}
          >
            <td>
              <input
                style={{ width: "100%" }}
                type="text"
                required
                placeholder="FirstName"
                name="firstName"
              />
            </td>
            <td>
              <input
                style={{ width: "100%" }}
                type="text"
                required
                placeholder="LastName"
                name="lastName"
              />
            </td>
          </tr>
          <tr className="home-contact-table-row">
            <td colSpan={2}>
              <input
                style={{ width: "100%" }}
                type="email"
                required
                placeholder="e-mail (example@ex.com)"
                name="email"
              ></input>
            </td>
          </tr>
          <tr className="home-contact-table-row">
            <td colSpan={2}>
              <input
                style={{ width: "100%" }}
                type="text"
                placeholder="Company (optional)"
                name="company"
              ></input>
            </td>
          </tr>
          <tr className="home-contact-table-row">
            <td colSpan={2}>
              <input
                style={{ width: "100%" }}
                type="text"
                placeholder="Title (optional)"
                name="title"
              ></input>
            </td>
          </tr>
          <tr className="home-contact-table-row">
            <td colSpan={2}>
              <input
                style={{ width: "100%" }}
                type="text"
                placeholder="Skype address (optional)"
                name="skype"
              ></input>
            </td>
          </tr>
          <tr className="home-contact-table-row">
            <td colSpan={2}>
              <input
                style={{ width: "100%" }}
                type="text"
                placeholder="Fiber account (optional)"
                name="fiber"
              ></input>
            </td>
          </tr>
          <tr className="home-contact-table-row">
            <td colSpan={2}>
              <textarea
                required
                name="sms"
                style={{ padding: "1rem" }}
                placeholder="input your text..."
              />
            </td>
          </tr>
          <tr colSpan={3}></tr>
          <tr style={{ border: "none" }}>
            <td colSpan={3}></td>
          </tr>
          <input
            type="submit"
            value="Submit"
            className="contact-button"
            // type="submit"
          ></input>
        </table>
      </form>
      {/* <div id="doneText" className="btn btn-success">
        Sent!
      </div> */}
    </div>
  );
}

function ShowResult() {
  const doneText = document.getElementById("doneText");
  setInterval(() => {
    setTimeout.classList.add("colorTest");
  }, 2000);
  // doneText.classList.remove("colorTest");
}
