import { LoremIpsum } from "react-lorem-ipsum";
import "../../../css/games-projects.scss";
import data from '../../../json/ProjectWebProjects.json';
import data2 from '../../../json/ProjectGamesGrid.json';
import data3 from '../../../json/ProjectsContentCreation.json';
import gameIcon from '../../../assets/images/icons/games.png'
import webIcon from '../../../assets/images/icons/web.png'

export default function ProjectsView() {
  return (
    <div className="Games-Projects" style={{position: "relative", margin: "auto", marginTop: "7rem" }}>
        <h1>Web Projects</h1>
        <hr/>
        <div className="Games-Projects-List">
            {
                data.map((data) => {
                    return (
                        <div className="Games-Projects-Project">
                            {data.type === "Game" ? <img src={data.picUrl} /> : <img src={data.picUrl} />}
                            <h3>{data.title}</h3>
                            <p>{data.text}</p>
                            <a style={{color:"#4943c9"}} href={data.link}>Read More</a>
                        </div>
                    );
                })
            }
        </div>
        <br/>
        <br/>
        <h1>Games</h1>
        <hr/>
        <div className="Games-Projects-List">
            {
                data2.map((data) => {
                    return (
                        <div className="Games-Projects-Project">
                            {data.type === "Game" ? <img src={data.picUrl} /> : <img src={webIcon} />}
                            <h3>{data.title}</h3>
                            <p>{data.text}</p>
                            <a style={{color:"#4943c9"}} href={data.link}>Read More</a>
                        </div>
                    );
                })
            }
        </div>
        <br/>
        <br/>
        <h1>Content Creation</h1>
        <hr/>
        <div className="Games-Projects-List">
            {
                data3.map((data) => {
                    return (
                        <div className="Games-Projects-Project">
                            {data.type === "Game" ? <img src={data.picUrl} /> : <img src={webIcon} />}
                            <h3>{data.title}</h3>
                            <p>{data.text}</p>
                            <a style={{color:"#4943c9"}} href={data.link}>Read More</a>
                        </div>
                    );
                })
            }
        </div>
    </div>
  );
}
{/* <div className="Games-Projects-Project">
                <h3>Title</h3>
                <LoremIpsum p={1} />
                <a href="/index.html?page=Projects">Read More</a>
            </div> */}
